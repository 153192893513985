body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,::after,::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: inherit
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth
}

@media only screen and (max-width: 75em) {
  html {
      font-size:59%
  }
}

@media only screen and (max-width: 56.25em) {
  html {
      font-size:56%
  }
}

@media only screen and (min-width: 112.5em) {
  html {
      font-size:65%
  }
}

body {
  box-sizing: border-box;
  position: relative;
  line-height: 1.5;
  font-family: sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: 'Source Sans Pro',sans-serif
}

a {
  text-decoration: none;
  color: inherit
}

li {
  list-style: none
}

a:focus,button:focus,input:focus,textarea:focus {
  outline: 0
}

button {
  border: none;
  cursor: pointer
}

textarea {
  resize: none
}

