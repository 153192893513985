.footer{
    position: relative;
    top: 60rem;
    background-color: black;
    color: #fff;
    display: flex;
    justify-content: center;
    bottom: 0;
    padding-top: 5rem;
}

.footer__content{
    display: flex;
    flex-direction: column;
    width: 90vw;
}

.footer__container{
    display: flex;
    flex-direction: column-reverse;
}

.myname,
.social__title{
    font-size: 2rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 1.5rem 0;
    font-weight: 600;
}

.my__description{
    font-size: 1.3rem;
    margin-bottom: 1rem;
    line-height: 1.7;
    color: #eee;
}

.footer__line{
    border-top: 1px solid #eeeeee71;
    display: grid;
    place-items: center;
    padding: 4rem 0;
    margin-top: 5rem;
}

.name__link{
  text-decoration: underline;
  font-size: 1.2rem;

}

.social__icon{
  display: flex;
  display: row;
  margin-bottom: 3rem;
}

.main-footer__icon{
  width: 2.5rem;
  margin-right: 1rem;
}

/* 600px */
@media screen and (min-width: 600px) {
  .footer{
    top: 0;
  }

  .footer__container{
    display: flex;
    flex-direction: row;
  }

  .social__container,
  .myname__container{
    display: flex;
    flex-direction: column;
    width: 95%;
    align-items: flex-start;
  }

  .social__container{
    width: 10%;
    align-items: flex-start;
  }

  .myname,
  .social__title{
    font-size: 2.2rem;
  }

  .my__description{
    font-size: 1.5rem;
    max-width: 50rem;
  }
}

/* 1440p laptop */
 @media screen and (min-width: 1440px) {
  .footer__content{
    max-width: 120rem;
  }
 }