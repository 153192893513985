.header {
  position: fixed;
  width: 100vw;
  z-index: 1000;
  background: #000;
  background: #fff;
  box-shadow: 0 10px 100px rgba(0, 0, 0, 0.1);
  height: 7rem;
  display: grid;
}

.header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;
}

.name {
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
}

.none {
  display: none;
}

.header__main {
  transition: 1s;
}

.home__hero {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4.5rem;
}

.heading__primary {
  display: grid;
  place-items: center;
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 3.1rem;
}

.text__primary {
  font-size: 1.6rem;
  display: grid;
  place-items: center;
  text-align: center;
}

.home__btn {
  border: 2px solid black;
  display: grid;
  place-items: center;
  text-align: center;
  width: 25rem;
  font-size: 1.6rem;
  padding: 1rem 5rem;
  background-color: bisque;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  border-radius: 5px;
  transition: 0.5s;
  border: 2px solid black;
}

.home__btn:hover {
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
}

.header__links {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* for mobile 600px */
@media screen and (max-width: 600px) {
  .header__links {
    position: absolute;
    top: 7rem;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 7rem;
    height: 100vh;
    font-size: 2.4rem;
    background-color: #b5b5b5a5;
    backdrop-filter: blur(10px);
    transition: 0.4s;
    padding-bottom: 7rem;
  }

  .header__link {
    border: 2px solid black;
    width: 20rem;
    display: grid;
    place-items: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    border-radius: 5px;
    padding: 1.5rem 5rem;
    font-size: 1.7rem;
    background-color: bisque;
  }

  .header__link:hover {
    border: 2px solid bisque;
    transition: 0.4s ease-in-out;
  }

  .header__link-hide {
    left: -100%;
  }

  .home__hero {
    position: absolute;
    top: 7rem;
    height: 70vh;
    display: grid;
    place-items: center;
    width: 100vw;
  }

  .heading__primary {
    font-size: 4rem;
    display: grid;
    place-items: center;
    text-align: center;
    width: 100%;
    font-weight: 600;
  }
}

/* remove mobile menu 600px */
@media screen and (min-width: 600px) {
  .hamburger__menu-content {
    display: none;
  }
}

/* 1024px laptop */

@media screen and (min-width: 1024px) {
  .heading__primary {
    font-size: 6rem;
    display: grid;
    place-items: center;
    text-align: center;
  }

  .text__primary {
    font-size: 2.2rem;
    max-width: 80vw;
  }

  .header__links {
    font-size: 1.6rem;
  }

  .name {
    font-size: 1.8rem;
  }
}
