*,::after,::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-family: inherit
}
  
html {
    font-size: 62.5%;
    scroll-behavior: smooth
}
  
body {
  box-sizing: border-box;
  position: relative;
  line-height: 1.5;
  font-family: sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: 'Source Sans Pro',sans-serif
}
  
a {
  text-decoration: none;
  color: inherit
}
  
li {
  list-style: none
}
  
a:focus,button:focus,input:focus,textarea:focus {
    outline: 0
}
  
button {
  border: none;
  cursor: pointer
}
  
textarea {
  resize: none
}

.projects{
  width: 100vw;
  position: relative;
  top: 60rem;
  display: flex;
  justify-content: center;
}

.projects__content{
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project__title{
  font-size: 3.1rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 4rem;
  text-transform: uppercase;
  letter-spacing: .5rem;
}

.project__description{
  font-size: 1.6rem;
  display: grid;
  place-items: center;
  text-align: center;
  padding-bottom: 4rem;
  color: #666;
}

.project__container{
  border: 5px solid black;
  background-color: #666;
  height: 20rem;
  width: 90%;
  margin-bottom: 4rem;
  border-radius: 1rem;
  background-image: url(../img/portfolio.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 310px;
}

.project__container_{
  border: 5px solid black;
  background-color: #666;
  height: 20rem;
  width: 90%;
  margin-bottom: 4rem;
  border-radius: 1rem;
  background-image: url(../img/math\ game.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 310px;
}

.single__project__title{
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
}

.single__project__description{
  font-size: 1.7rem;
  display: grid;
  place-items: center;
  text-align: center;
  padding-bottom: 2rem;
  color: #666;
  line-height: 3rem;
  max-width: 60rem;
  color: #666;
}

.case__study__btn{
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 2.5rem;
  font-weight: 700;
  border-radius: 5px;
  transition: .5s;
  padding: 1.2rem 4rem;
  font-size: 1.6rem;
  background-color: bisque;
  border: 2px solid black;
  color: black;
}

.case__study__btn:hover{
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
}


.sinlge__project-container{
  width: 90vw;
  margin-bottom: 15rem;
  display: grid;
  place-items: center;
  text-align: center;
}


/* 600px */
@media screen and (min-width: 600px) {
  .projects{
    top: 0;
  }

  .project__container,
  .project__container_{
    height: 40rem;
    width: 62rem;
    background-size: 620px;
  }

  .project__description{
  max-width: 75rem;
  }
  
  .single__project__description{
 max-width: 55rem;
  }
}

/* 1024px */
@media screen and (min-width: 1024px) {

  .project__title{
    font-size: 4rem;
  }

  .project__description{
    max-width: 75%;
    font-size: 2rem;
    padding-bottom: 10rem;
  }

  .case__study__btn{
    margin-bottom: 0rem;
    margin-top: 0rem;
    font-size: 2rem;
  }

  
  .project__mini-container{
    width: 50%;
    align-items: flex-start;
    margin-left: 4rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  .single__project__description{
    text-align: left;
    padding-bottom: 0rem;
    font-size: 1.8rem;
  }

  .single__project__title{
    margin-bottom: 0rem;
    font-size: 2.8rem;
  }

  .project__container,
  .project__container_{
    margin-bottom: 0rem;
    width: 50rem;
    height: 30rem;
    background-size: 460px;
  }

  .sinlge__project-container{
    width: 90vw;
    margin-bottom: 15rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
  } 
}

/* 1440p laptop */

@media screen and (min-width: 1440px) {
  .sinlge__project-container{
    max-width: 115rem  
  }

  .project__description{
    max-width: 80rem;
  }

  .project__container,
  .project__container_{
    background-size: 600px;
    height: 38rem;
    width: 65rem;
  }
}







