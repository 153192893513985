.contact {
  position: relative;
  top: 60rem;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
}

.contact__content {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact__title {
  display: grid;
  place-items: center;
  text-align: center;
  width: 100%;
  font-size: 3.1rem;
  font-weight: 600;
  padding-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  padding-top: 10rem;
}

.contact__description {
  font-size: 1.6rem;
  display: grid;
  place-items: center;
  text-align: center;
  padding-bottom: 9rem;
  color: #666;
}

.form__container {
  width: 90vw;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  padding: 3rem;
  margin-bottom: 10rem;
}

input,
textarea {
  width: 100%;
  color: #333;
  padding: 2rem;
  border: 1px solid #ebebeb;
  font-size: 1.6rem;
  letter-spacing: 0;
  background: #f0f0f0;
  border-radius: 5px;
  font-weight: 600;
  margin-bottom: 5rem;
}

textarea {
  height: 25rem;
  resize: none;
}

.submit__btn {
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  border-radius: 5px;
  transition: 0.5s;
  padding: 1rem 4rem;
  font-size: 1.6rem;
  background-color: bisque;
  border: 2px solid black;
  margin-bottom: 2rem;
  color: black;
}

.submit__btn:hover {
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
}

.contact__label h2 {
  font-weight: 700;
  margin-bottom: 1rem;
  display: block;
  text-align: left;
}

/* 600px */
@media screen and (min-width: 600px) {
  .submit__btn {
    width: 30%;
  }

  .contact {
    top: 0;
  }

  .contact__description {
    max-width: 90%;
  }
}

/* 1024px */
@media screen and (min-width: 1024px) {
  .contact__description {
    max-width: 70%;
    font-size: 2rem;
  }

  .form__container {
    max-width: 85rem;
  }

  .submit__btn {
    font-size: 2rem;
  }

  .contact__title {
    font-size: 4rem;
  }
}

/* 1440p laptop */
@media screen and (min-width: 1440px) {
  .form__container {
    max-width: 85rem;
  }

  .contact__description {
    max-width: 80rem;
  }
}
